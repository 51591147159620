import React, { useState } from "react";
import { Button, Col, Row } from "reactstrap";

import Widget from "src/components/Widget";
import { googleMapAPI } from "src/config/secret";

import s from "./Dashboard.module.scss";
import DisplayMap from "./components/DisplayMap/";
import MoistureChart from "./components/charts/MoistureCharts";
import BatteryLevelChart from "./components/charts/BatteryChart";
import WeatherTable from "./components/WeatherTable";
import EvaporationChart from "./components/charts/EvaporationChart";
import PrecipitationChart from "./components/charts/PrecipitationChart";
import { FieldMoistureDataContextProvider } from "./hooks/useFieldMoistureDataContext";
import { CSVModalContextProvider } from "./hooks/useCSVModalContext";
import CSVModal from "./components/modals/DownloadCSVModal";
import DownloadReportModal from "./components/modals/DownloadReportModal";
import { IComponentType } from "src/types";
import { withRouter } from "react-router";
import FieldFarmSelector from "./components/selectors/FieldFarmSelector";

type DashboardProps = {};

export const FIELD_QUERY_KEY = "field";
export const DETIAL_PAGE_QUERY_KEY = "detail";
export const SENSOR_QUERY_KEY: "sensor" | "compareSensor" = "sensor";
export const COMPARED_SENSOR_QUERY_KEY: "sensor" | "compareSensor" = "compareSensor";

const Dashboard: IComponentType<DashboardProps> = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <div className={s.root}>
      <FieldMoistureDataContextProvider>
        {/* Heading Row */}
        <Row className={s.title}>
          <Col sm={12} md={7}>
            <h1 className="mb-lg">Dashboard</h1>
          </Col>

          {/*Download a status report*/}
          <Col sm={12} md={3}>
            <Button
              onClick={() => setModalOpen((prev) => !prev)}
              outline
              color="danger"
              className="floar-right"
            // disabled
            >
              <span className="glyphicon glyphicon-download-alt"></span>{" "}
              Download Report
            </Button>
          </Col>
          <DownloadReportModal
            isOpen={isModalOpen}
            toggle={() => setModalOpen((prev) => !prev)}
          />
          {/* Farm-Field Selection*/}
          <Col sm={12} md={2}>
            <FieldFarmSelector className="float-right" color="primary" flat />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col sm={12} md={4} className={"order-last"}>
            <Widget className={s.stretch}>
              <WeatherTable />
            </Widget>
          </Col>

          <Col sm={12} md={8}>
            <div className={s.MapContainer}>
              <DisplayMap
                containerElement={<div style={{ height: "inherit" }} />}
                mapElement={<div style={{ height: "inherit" }} />}
                googleMapURL={googleMapAPI}
                loadingElement={
                  <div style={{ height: "inherit", width: "inherit" }} />
                }
                inDetailPage={true}
              />
            </div>
          </Col>
        </Row>
        <CSVModalContextProvider>
          <CSVModal />
          <DetailedDashboard />
        </CSVModalContextProvider>
      </FieldMoistureDataContextProvider>
    </div>
  );
};

const DetailedDashboard = () => {
  return (
    <>
      <MoistureChart />

      <Row className={s.body}>
        <Col sm={12} md={12}>
          <Widget className={s.stretch} title={<h4>Evaporation</h4>}>
            <EvaporationChart />
          </Widget>
        </Col>
      </Row>

      <Row className={s.body}>
        <Col sm={12} md={12}>
          <Widget
            className={s.stretch}
            title={<h4>Precipitation Variation</h4>}
          >
            <PrecipitationChart />
          </Widget>
        </Col>
      </Row>

      <Row className={s.body}>
        <Col sm={12} md={12}>
          <BatteryLevelChart />
        </Col>
      </Row>
    </>
  );
};

export default withRouter(Dashboard);
