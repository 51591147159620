import { colors } from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Circle,
  GoogleMap,
  InfoWindow,
  Polygon,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import { Field } from "src/actions/v2/field";
import { Zone } from "src/actions/v2/zone";

type ReportMapProps = {
  field: Field;
  zones: Zone[];
  sensors: { [sensorId: string]: { lat: number; lng: number } };
  selectedSensors?: string[];
  onSensorClick?: (sensorId: string) => void;
};

export const ZONE_POLYGON_OPTIONS = [
  {
    strokeColor: colors.pink.A100,
    fillOpacity: 0,
    zIndex: -1,
  },
  {
    strokeColor: colors.purple.A100,
    fillOpacity: 0,
    zIndex: -1,
  },
];

const SELECTED_SENSOR_OPTIONS = {
  fillColor: colors.deepOrange[500],
  fillOpacity: 0.8,
  strokeWeight: 3,
  strokeColor: colors.deepOrange[700],
};
const UNSELECTED_SENSOR_OPTIONS = {
  fillColor: colors.teal[500],
  fillOpacity: 0.8,
  strokeWeight: 3,
  strokeColor: colors.teal[700],
};

const ReportMap = forwardRef<any, ReportMapProps>(
  ({ field, zones, sensors, selectedSensors, onSensorClick }, ref) => {
    const mapRef = useRef<GoogleMap>(null);
    const [showInfo, setShowInfo] = useState<{ [id: string]: boolean }>(
      Object.fromEntries(Object.keys(sensors).map((sensor) => [sensor, false]))
    );

    const autoZoom = (points: google.maps.LatLngLiteral[], map: GoogleMap) => {
      if (points.length > 0) {
        let bounds = new google.maps.LatLngBounds();
        points.forEach((p) => bounds.extend(p));
        map.fitBounds(bounds);
        // setZoom(map.getZoom())
      }
    };

    useImperativeHandle(ref, () => ({
      getMapInstance() { return mapRef.current },
    }), []);

    useEffect(() => {
      if (!mapRef.current) return;
      if (field?.coordinates) {
        autoZoom(field.coordinates, mapRef.current);
        // setCenter(getCenter(field.coordinates));
      }
    }, [field, mapRef]);

    return (
      <GoogleMap
        mapTypeId="satellite"
        // center={props.CurrentLocation}
        ref={mapRef}
        options={{
          scrollwheel: false,
          disableDefaultUI: true,
          zoomControl: false,
          disableDoubleClickZoom: true,
        }}
      >
        <Polygon
          options={{
            strokeColor: "#369BF7",
            fillOpacity: 0,
            zIndex: -1,
          }}
          path={field.coordinates}
        />
        {zones.map((zone, i) => (
          <Polygon
            key={i}
            options={ZONE_POLYGON_OPTIONS[i]}
            path={zone.points}
          />
        ))}
        {Object.entries(sensors).map(([sensor, coordinates], i) => (
          <React.Fragment key={i}>
            <Circle
              radius={8}
              options={
                selectedSensors?.includes(sensor)
                  ? SELECTED_SENSOR_OPTIONS
                  : UNSELECTED_SENSOR_OPTIONS
              }
              center={coordinates}
              onClick={() => {
                setShowInfo((prev) => ({ ...prev, [sensor]: !prev[sensor] }));
                if (onSensorClick) {
                  onSensorClick(sensor);
                }
              }}
            />
            {showInfo[sensor] && (
              <InfoWindow
                position={coordinates}
                onCloseClick={() =>
                  setShowInfo((prev) => ({ ...prev, [sensor]: false }))
                }
              >
                <span>{sensor}</span>
              </InfoWindow>
            )}
          </React.Fragment>
        ))}
      </GoogleMap>
    );
  }
);

export default withScriptjs(withGoogleMap<ReportMapProps>(ReportMap));
