import React, { RefObject, useEffect, useMemo, useRef, useState } from "react";
import SensorSelector from "../selectors/SensorSelectorV2";
import { isSensorMultilayer } from "src/actions/v2/sensor";
import { Button, ButtonGroup, Spinner } from "reactstrap";
import {
  CartesianGrid,
  Legend,
  LineChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  TooltipFormatter,
  XAxis,
  YAxis,
} from "recharts";
import { formatDate } from "../../util";
import { ZoneSoilProfile } from "src/actions/v2/zone";
import { LineWithController } from "../../../../components/CustomLines";
import {
  SensorMoistureData,
  useFieldMoistureDataContext,
} from "../../hooks/useFieldMoistureDataContext";
import { useCSVModalContext } from "../../hooks/useCSVModalContext";
import { Moisture_Colors } from "../../constants";
import { withRouter } from "react-router";
import { IComponentType } from "src/types";

const DEPTHS_MAPPING = {
  cap50_percent: "10 cm",
  cap100_percent: "100 cm",
  cap150_percent: "150 cm",
};

const OTHER_CONTROLS_MAPPING = {
  temperature: "Temperature",
  wp: "Wilting Point",
  fc: "Field Capacity",
  sat: "Saturation Point",
};

const initialShowLineState: { [key: string]: boolean } = {
  cap50_percent: false,
  cap100_percent: false,
  cap150_percent: false,
  temperature: false,
  wp: false,
  fc: false,
  sat: false,
};

const getLineNames: (
  isMultiLayer: boolean
) => Record<keyof Omit<SensorMoistureData, "time">, string | undefined> = (
  isMultiLayer
) => {
    return {
      temperature: "Temperature",
      cap50_percent: !isMultiLayer ? "Moisture" : "Moisture - 10cm underground",
      cap100_percent: !isMultiLayer ? undefined : "Moisture - 100cm underground",
      cap150_percent: !isMultiLayer ? undefined : "Moisture - 150cm underground",
    };
  };

const COLORS = {
  temperature: "#f3c363",
  cap50_percent: "#8884d8",
  wpoint_50: "#8884d8",
  fcapacity_50: "#8884d8",
  saturation_50: "#8884d8",
  cap100_percent: "#82ca9d",
  wpoint_100: "#82ca9d",
  fcapacity_100: "#82ca9d",
  saturation_100: "#82ca9d",
  cap150_percent: "#fc3903",
  wpoint_150: "#fc3903",
  fcapacity_150: "#fc3903",
  saturation_150: "#fc3903",
};

const toolTipFormatter: TooltipFormatter = (value, title) => {
  const v = typeof value === "number" ? value.toFixed(2) : value;
  const unit =
    title === "Temperature" || title === "Prediction Temperature" ? "℃" : "%";
  return `${v} ${unit}`;
};

type MoistureTemperatureChartProps = {
  queryKey?: "sensor" | "compareSensor";
};

const MoistureTemperatureChart: IComponentType<MoistureTemperatureChartProps> = ({
  queryKey,
  location,
  history
}) => {
  const { toggle } = useCSVModalContext();
  const sensor = location.state?.[queryKey || 'sensor']!
  const isMultiDepthSensor = useMemo(
    () => !sensor ? false : isSensorMultilayer(sensor.sensor_id),
    [sensor]
  );
  const [showLines, setShowLines] = useState(initialShowLineState);
  const refs: Record<keyof typeof initialShowLineState, RefObject<Button>> = {
    cap50_percent: useRef(null),
    cap100_percent: useRef(null),
    cap150_percent: useRef(null),
    temperature: useRef(null),
    wp: useRef(null),
    fc: useRef(null),
    sat: useRef(null),
  };
  const { getMoistureData, isFetching } = useFieldMoistureDataContext();
  const { moisture, prediction, zoneProfile } = useMemo(() => {
    if (!sensor) return {}
    let { moisture, prediction, zoneProfile } = getMoistureData(
      sensor.sensor_id
    );
    if (prediction && moisture) {
      let moistureData = moisture.sort((a, b) => a.time - b.time);
      const predictionData: SensorMoistureData[] = prediction.map((p) => ({
        time: new Date(p.name).getTime(),
        temperature: p.Temperature,
        cap50_percent: p.Layer_1,
        cap100_percent: p.Layer_9,
        cap150_percent: p.Layer_14,
      }));
      return {
        moisture: moistureData,
        prediction: [moistureData[moistureData.length - 1], ...predictionData],
        zoneProfile,
      };
    }
    return { moisture, prediction, zoneProfile } as {
      moisture?: SensorMoistureData[];
      prediction?: SensorMoistureData[];
      zoneProfile?: ZoneSoilProfile;
    };
  }, [getMoistureData, sensor]);

  useEffect(() => {
    setShowLines((prev) =>
      isMultiDepthSensor
        ? {
          ...prev,
          cap50_percent: true,
          cap100_percent: true,
          cap150_percent: true,
          temperature: true,
        }
        : { ...prev, cap50_percent: true, temperature: true }
    );
  }, [isMultiDepthSensor]);

  const handleLegendOnClick = (k: keyof typeof showLines) => () =>
    setShowLines((prev) => ({ ...prev, [k]: !prev[k] }));

  const MultiLayerShowingControls = () => (
    <>
      {Object.entries(DEPTHS_MAPPING).map(([k, depth], i) => {
        return (
          <Button
            color="primary"
            outline
            active={showLines[k]}
            key={i}
            onClick={handleLegendOnClick(k)}
            ref={refs[k]}
          >
            {depth}
          </Button>
        );
      })}
    </>
  );

  const SingleLayerShowingControls = () => (
    <Button
      color="primary"
      outline
      active={showLines.cap50_percent}
      onClick={handleLegendOnClick("cap50_percent")}
      ref={refs.cap50_percent}
    >
      Moisture
    </Button>
  );

  return (
    <>
      <SensorSelector
        classname="float-left"
        queryKey={queryKey}
      />
      {/* Legend buttons */}
      <>
        <ButtonGroup className="ml-2">
          {isMultiDepthSensor ? (
            <MultiLayerShowingControls />
          ) : (
            <SingleLayerShowingControls />
          )}
        </ButtonGroup>

        <ButtonGroup className="ml-2">
          {Object.entries(OTHER_CONTROLS_MAPPING).map(([t, label], i) => {
            const k = t as keyof typeof showLines;
            return (
              <Button
                color="primary"
                outline
                key={i}
                onClick={handleLegendOnClick(k)}
                active={showLines[k]}
                ref={refs[k]}
              >
                {label}
              </Button>
            );
          })}

          <ButtonGroup className="ml-2">
            <Button
              outline
              color="danger"
              className="d-flex"
              onClick={toggle(sensor?.sensor_id)}
            >
              <span className="glyphicon glyphicon-download-alt align-self-center"></span>
              <span className="ml-2">Download Data</span>
            </Button>
          </ButtonGroup>
        </ButtonGroup>
      </>
      <ResponsiveContainer height={400} width={"100%"}>
        {isFetching || !moisture ? (
          // fetching spinner
          <div className="d-flex flex-column h-100 justify-content-center ">
            <Spinner className="mx-auto d-block" />
          </div>
        ) : moisture.length === 0 ? (
          // fetching completed but has no data
          <div className="d-flex flex-column h-100 justify-content-center ">
            <div className="text-center h3 mt-4 d-block">No Sensor Data</div>
          </div>
        ) : (
          // fetching completed with data
          <LineChart
            layout="horizontal"
            margin={{
              top: 20,
              right: 0,
              left: -10,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            {/* x-axis for time */}
            <XAxis
              dataKey="time"
              type="category"
              domain={[0, "auto"]}
              height={80}
              interval={Math.trunc(moisture.length / 8)}
              tickCount={0}
              allowDuplicatedCategory={false}
              orientation="bottom"
              tickFormatter={formatDate}
            />
            {/* left y axis for moisture measurement */}
            <YAxis
              tickCount={3}
              width={80}
              yAxisId="left"
              dataKey="value"
              unit="%"
              type="number"
              allowDuplicatedCategory={false}
            />
            {/* right y axis for temperature */}
            <YAxis yAxisId="right" orientation="right" unit="℃" />

            <Tooltip
              formatter={toolTipFormatter}
              labelFormatter={(label) =>
                new Date(label).toString().substring(0, 25)
              }
            />

            <Legend
              verticalAlign="top"
              margin={{ bottom: 50 }}
              formatter={(value: string) =>
                value.replace(" underground", "").replace(" -", "")
              }
            />
            {/* Showing the moisture measurement */}
            {Object.entries(getLineNames(isMultiDepthSensor)).map(
              ([key, name], i) =>
                name && (
                  <LineWithController
                    key={i}
                    yAxisId={key === "temperature" ? "right" : "left"}
                    dataKey={"value"}
                    name={name}
                    strokeWidth={1}
                    type="monotone"
                    dot={false}
                    data={moisture
                      .sort((a, b) => a.time - b.time)
                      .map((d) => ({
                        value: d[key as keyof SensorMoistureData],
                        time: d.time,
                      }))}
                    stroke={
                      COLORS[key as keyof Omit<SensorMoistureData, "time">]
                    }
                    connectNulls
                    show={showLines[key]}
                  />
                )
            )}

            {/* Showing prediction data */}
            {prediction &&
              Object.entries(getLineNames(isMultiDepthSensor)).map(
                ([key, name], i) =>
                  name &&
                  showLines[key] && (
                    <LineWithController
                      key={`prediction-${i}`}
                      yAxisId={key === "temperature" ? "right" : "left"}
                      dataKey={"value"}
                      name={
                        name.includes("Moisture")
                          ? name.replace("Moisture", "Prediction")
                          : `Prediction ${name}`
                      }
                      strokeWidth={1}
                      type="monotone"
                      dot={false}
                      data={prediction.map((d) => ({
                        value: d[key as keyof SensorMoistureData],
                        time: d.time,
                      }))}
                      stroke={
                        COLORS[key as keyof Omit<SensorMoistureData, "time">]
                      }
                      show={showLines[key]}
                      strokeDasharray="6 6"
                    />
                  )
              )}

            {/* Showing soil profile of the zone the sensor sits in */}
            {zoneProfile &&
              Array.from([
                [
                  undefined,
                  zoneProfile[
                  `wpoint_${zoneProfile.interest_layer}` as keyof ZoneSoilProfile
                  ],
                ],
                [
                  zoneProfile[
                  `wpoint_${zoneProfile.interest_layer}` as keyof ZoneSoilProfile
                  ],
                  zoneProfile[
                  `fcapacity_${zoneProfile.interest_layer}` as keyof ZoneSoilProfile
                  ],
                ],
                [
                  zoneProfile[
                  `fcapacity_${zoneProfile.interest_layer}` as keyof ZoneSoilProfile
                  ],
                  zoneProfile[
                  `saturation_${zoneProfile.interest_layer}` as keyof ZoneSoilProfile
                  ],
                ],
                [
                  zoneProfile[
                  `saturation_${zoneProfile.interest_layer}` as keyof ZoneSoilProfile
                  ],
                  undefined,
                ],
              ]).map(([y1, y2], i) => (
                <ReferenceArea
                  key={i}
                  y1={y1}
                  y2={y2}
                  yAxisId={"left"}
                  fill={Moisture_Colors[i]}
                  fillOpacity={0.2}
                  ifOverflow="extendDomain"
                />
              ))}
          </LineChart>
        )}
      </ResponsiveContainer>
    </>
  );
};

export default withRouter(MoistureTemperatureChart);
