import React, { RefObject, useMemo, useRef, useState } from "react";
import { Button, ButtonGroup, Spinner } from "reactstrap";
import SensorSelector from "../selectors/SensorSelectorV2";
import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  TooltipFormatter,
  XAxis,
  YAxis,
} from "recharts";
import { PredictionData } from "src/actions/v2/prediction";
import {
  useFieldMoistureDataContext,
  SensorMoistureData,
} from "../../hooks/useFieldMoistureDataContext";
import { ZoneSoilProfile } from "src/actions/v2/zone";
import { Moisture_Colors } from "../../constants";
import { withRouter } from "react-router";
import { IComponentType } from "src/types";
import { toast } from "react-toastify";

const initialShowLineState = {
  wp: false,
  fc: false,
};

// const CONTROL_MAPPING = {
//   wp: "Wilting Point",
//   fc: "Field Capacity",
// };

const DAYS_IN_PAST = ['Yesterday', 'Today']
const DAYS_IN_FUTURE = ['2hrs Later', 'Tomorrow']

const COLORS = ["#f3c363", "#82ca9d", "#8884d8"];
const PREDICTION_COLORS = ["#8884d8", "#db63f3", "#fc3903"];

type MoistureDepthDataType = {
  time: number;
  data: { [depth in `${number}cm`]: number | null };
};

const toolTipFormatter: TooltipFormatter = (value, title) => {
  const v = typeof value === "number" ? value.toFixed(2) : value;
  const unit =
    title === "Temperature" || title === "Prediction Temperature" ? "℃" : "%";
  return `${v} ${unit}`;
};

interface MoistureDepthChartProps {
  queryKey?: "sensor" | "compareSensor";
}

const MoistureDepthChart: IComponentType<MoistureDepthChartProps> = ({
  queryKey,
  location,
}) => {
  const sensor = location.state?.[queryKey || 'sensor']!

  // const [showLines, setShowLines] = useState(initialShowLineState);
  const refs: Record<
    keyof typeof initialShowLineState | "maxDepth",
    RefObject<Button>
  > = {
    wp: useRef(null),
    fc: useRef(null),
    maxDepth: useRef(null),
  };
  const [showMaxDepth, setShowMaxpDepth] = useState(false);

  const { getMoistureData, isFetching } = useFieldMoistureDataContext();

  const { moisture, prediction, zoneProfile } = useMemo(() => {
    if (!sensor) return {}
    let { moisture, prediction, zoneProfile } = getMoistureData(
      sensor.sensor_id
    );
    // here we need to get data for the last three days one data for each day for moisture
    let calculatedMoisture = getMoistureDataForLastThreedays(
      moisture?.sort((a, b) => a.time - b.time)
    );
    // here we need to get prediction for the later three days on the similar hour
    let calculatedPrediction = getPredictionForNextThreedays(prediction);
    return {
      moisture: calculatedMoisture,
      prediction: calculatedPrediction,
      zoneProfile,
    };
  }, [getMoistureData, sensor]);

  // const handleControlOnClick = (k: keyof typeof showLines) => () => {
  //   setShowLines((prev) => ({ ...prev, [k]: !prev[k] }));
  // };

  function getMoistureDataForLastThreedays(
    moisture?: SensorMoistureData[]
  ): MoistureDepthDataType[] | undefined {
    if (!moisture) return;

    let TWO_DAYS_BEFORE = new Date();
    // need to cover one day more
    TWO_DAYS_BEFORE.setDate(TWO_DAYS_BEFORE.getDate() - 2);

    const populateMoistureAgainstDepth = (
      m: SensorMoistureData
    ): MoistureDepthDataType => {
      let data: MoistureDepthDataType = {
        time: m.time,
        data: {
          "-10cm": m.cap50_percent,
          "-100cm": m.cap100_percent || m.cap50_percent,
          "-150cm": m.cap150_percent || m.cap50_percent,
        },
      };
      for (let i = 0; i > -150; i -= 10) {
        if (data.data[`${i}cm`]) continue;
        data.data[`${i}cm`] = null;
      }
      return data;
    };

    let result: MoistureDepthDataType[] = [];
    let targetTime: Date | undefined;
    let lastDifferenceToTargetTime = Number.MAX_SAFE_INTEGER;
    let lastIndex = 0;

    for (const [i, m] of moisture.reverse().entries()) {
      if (m.time < TWO_DAYS_BEFORE.getTime()) break;
      if (result.length === 3) break;

      let time = new Date(m.time);
      if (!targetTime) {
        targetTime = time;
        targetTime.setDate(targetTime.getDate() - 1);
        result.push(populateMoistureAgainstDepth(m));
        continue;
      }
      if (
        Math.abs(m.time - targetTime.getTime()) < lastDifferenceToTargetTime
      ) {
        lastDifferenceToTargetTime = Math.abs(m.time - targetTime.getTime());
      } else {
        targetTime.setDate(targetTime.getDate() - 1);
        lastDifferenceToTargetTime = Number.MAX_SAFE_INTEGER;
        result.push(populateMoistureAgainstDepth(moisture[lastIndex]));
      }

      lastIndex = i;
    }
    return result;
  }

  function getPredictionForNextThreedays(
    prediction?: PredictionData[]
  ): MoistureDepthDataType[] {
    if (!prediction)
      return [
        {
          time: new Date().getTime(),
          data: Object.fromEntries(
            Array.from({ length: 16 }, (_, index) => index).map((depth) => [
              `${-depth * 10}cm`,
              null,
            ])
          ),
        },
      ];

    const populatePredictionMoistureAgainstDepth = (
      prediction: PredictionData
    ) => {
      let data: MoistureDepthDataType = {
        time: new Date(prediction.name).getTime(),
        data: {},
      };
      for (let i = 0; i < 20; i++) {
        data.data[`${-i * 10}cm`] = prediction[`Layer_${i}`] || null;
      }
      return data;
    };

    let TWO_DAYS_LATER = new Date();
    // need to cover one day more
    TWO_DAYS_LATER.setDate(TWO_DAYS_LATER.getDate() + 2);

    let result: MoistureDepthDataType[] = [];
    let targetTime: Date | undefined;
    let lastDifferenceToTargetTime = Number.MAX_SAFE_INTEGER;
    let lastIndex = 0;

    for (const [i, p] of prediction.entries()) {
      let timestamp = new Date(p.name).getTime();
      if (new Date(p.name).getTime() > TWO_DAYS_LATER.getTime()) break;
      if (result.length === 3) break;

      if (!targetTime) {
        targetTime = new Date(p.name);
        targetTime.setDate(targetTime.getDate() + 1);
        result.push(populatePredictionMoistureAgainstDepth(p));
      }

      if (timestamp - targetTime.getTime() < lastDifferenceToTargetTime) {
        lastDifferenceToTargetTime = Math.abs(timestamp - targetTime.getTime());
      } else {
        targetTime.setDate(targetTime.getDate() + 1);
        lastDifferenceToTargetTime = Number.MAX_SAFE_INTEGER;
        result.push(
          populatePredictionMoistureAgainstDepth(prediction[lastIndex])
        );
      }
      lastIndex = i;
    }

    return result;
  }

  const downloadData = () => {
    if (!moisture) return;
    try {
      // format csv data
      let csv = formatData();
      // download csv data
      let filename = `${sensor.sensor_id}.csv`;
      if (!csv.match(/^data:text\/csv/i)) {
        csv = "data:text/csv;charset=utf-8," + csv;
      }
      let data = encodeURI(csv);
      let link = document.createElement("a");
      link.href = data;
      link.download = filename;
      link.click();
      toast.success(
        <div>
          <span className="glyphicon glyphicon-floppy-saved ml-2 mr-2" />
          File has downloaded
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: true,
        }
      );
    } catch (err) {
      toast.error(<div>
        <span className="glyphicon glyphicon-floppy-remove ml-2 mr-2" />
        Fail to download CSV file
      </div>,
        {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
        });
      console.log(err);
    }
  }

  function formatData(): string {
    const DEPTH = [...Array(21).keys()].map(d => `${d * 10 * -1}cm`)

    let result = '';

    let data = [...moisture!, ...(prediction || [])].sort((a, b) => a.time - b.time)

    data.forEach(d => {
      let time = new Date(d.time);
      let timeString = `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()} ${time.getHours()}:${time.getMinutes()}`
      result += `,${timeString}`
    })

    DEPTH.forEach(depth => {
      result += `\n${depth}`

      data.forEach(d => {
        result += `,${d.data[depth as `${number}cm`]?.toFixed(2) || ''}`
      })
    })
    return result;
  }

  return (
    <>
      <SensorSelector
        classname="float-left"
        queryKey={queryKey}
      />
      {/* Controls */}
      <ButtonGroup className="ml-2">
        {/* {Object.entries(CONTROL_MAPPING).map(([t, label], i) => {
          const k = t as keyof typeof showLines;
          return (
            <Button
              color="primary"
              outline
              key={i}
              onClick={handleControlOnClick(k)}
              active={showLines[k]}
              ref={refs[k]}
            >
              {label}
            </Button>
          );
        })} */}
        <Button
          color="primary"
          outline
          active={showMaxDepth}
          onClick={() => setShowMaxpDepth((prev) => !prev)}
          ref={refs.maxDepth}
        >
          Max Depth
        </Button>
      </ButtonGroup>

      {
        !!moisture && moisture.length > 0 &&
        <ButtonGroup className="ml-2">
          <Button
            outline
            color="danger"
            className="d-flex"
            onClick={downloadData}
          >
            <span className="glyphicon glyphicon-download-alt align-self-center"></span>
            <span className="ml-2">Download Data</span>
          </Button>
        </ButtonGroup>
      }

      <ResponsiveContainer height={400} width={"100%"}>
        {isFetching || !moisture ? (
          <div className="d-flex flex-column h-100 justify-content-center ">
            <Spinner className="mx-auto d-block" />
          </div>
        ) : moisture.length === 0 ? (
          // fetching completed but has no data
          <div className="d-flex flex-column h-100 justify-content-center ">
            <div className="text-center h3 mt-4 d-block">No Sensor Data</div>
          </div>
        ) : (
          <LineChart
            layout="vertical"
            margin={{ top: -50, left: -10, right: 0, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis
              dataKey="value"
              type="number"
              domain={["dataMin-0.2", "dataMax+0.2"]}
              height={80}
              tickCount={18}
              allowDuplicatedCategory={false}
              orientation="top"
              tickFormatter={(value: number) => value.toFixed(1)}
              unit={"%"}
              xAxisId={"top"}
            />
            <YAxis
              tickCount={3}
              width={80}
              yAxisId="left"
              dataKey="depth"
              type="category"
              allowDuplicatedCategory={false}
            />
            <YAxis yAxisId="right" orientation="right" unit="℃" />
            <Tooltip formatter={toolTipFormatter} />
            {moisture
              .sort((a, b) => a.time - b.time)
              .map((m, i) => (
                <Line
                  key={`moisture-${i}`}
                  yAxisId="left"
                  dataKey="value"
                  name={DAYS_IN_PAST[i]}
                  strokeWidth={1}
                  type="monotone"
                  data={Object.entries(m.data)
                    .map(([depth, value]) => ({
                      depth,
                      value,
                    }))
                    .sort(
                      (a, b) =>
                        +b.depth.match(/(-?\d+)cm/)![1] -
                        +a.depth.match(/(-?\d+)cm/)![1]
                    )}
                  stroke={COLORS[i]}
                  connectNulls={true}
                  xAxisId="top"
                />
              ))}

            {prediction.map((p, i) => (
              <Line
                key={`prediction-${i}`}
                yAxisId="left"
                dataKey="value"
                name={DAYS_IN_FUTURE[i]}
                strokeWidth={1}
                type="monotone"
                data={Object.entries(p.data)
                  .map(([depth, value]) => ({
                    depth,
                    value,
                  }))
                  .sort(
                    (a, b) =>
                      +b.depth.match(/(-?\d+)cm/)![1] -
                      +a.depth.match(/(-?\d+)cm/)![1]
                  )
                  .slice(0, showMaxDepth ? undefined : 16)}
                stroke={PREDICTION_COLORS[i]}
                strokeDasharray={i === 0 ? undefined : "6 6"}
                connectNulls
                xAxisId="top"
              />
            ))}

            {zoneProfile &&
              Array.from([50, 100, 150]).map((depth, i, arr) => {
                const thresholds = [
                  0,
                  zoneProfile[`wpoint_${depth}` as keyof ZoneSoilProfile],
                  zoneProfile[`fcapacity_${depth}` as keyof ZoneSoilProfile],
                  zoneProfile[`saturation_${depth}` as keyof ZoneSoilProfile],
                  100,
                ];
                const data = thresholds.slice(0, -1).map((lower, j) => ({
                  lower,
                  higher: thresholds[j + 1],
                }));
                const labels = ["< WP", "WP - FC", "FC - SAT", "> SAT"];

                return data.map(({ lower, higher }, j) => (
                  <ReferenceArea
                    key={`${depth}-${j}`}
                    x1={lower}
                    x2={higher}
                    y1={i === 0 ? "0cm" : `-${arr[i - 1]}cm`}
                    y2={`-${depth}cm`}
                    label={
                      <Label
                        value={labels[j]}
                        fontSize="1rem"
                        fontWeight="bold"
                        fill={Moisture_Colors[j]}
                        style={{ writingMode: "vertical-rl" }}
                      />
                    }
                    stroke={Moisture_Colors[j]}
                    strokeOpacity={'0.2'}
                    fill={Moisture_Colors[j]}
                    fillOpacity={'0.2'}
                    xAxisId="top"
                    yAxisId="left"
                  // ifOverflow="extendDomain"
                  />
                ));
              })}
          </LineChart>
        )}
      </ResponsiveContainer>
    </>
  );
};

export default withRouter(MoistureDepthChart);
