import { AxiosRequestConfig } from "axios";
import axiosInstance from "../axios/global";

const reqConfig: AxiosRequestConfig = {
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};

export type RawZone = {
  id?: string;
  zonename?: string;
  croptype?: string;
  soiltype_25?: string;
  soiltype_75?: string;
  soiltype_125?: string;
  wpoint_50?: number;
  wpoint_100?: number;
  wpoint_150?: number;
  fcapacity_50?: number;
  fcapacity_100?: number;
  fcapacity_150?: number;
  saturation_50?: number;
  saturation_100?: number;
  saturation_150?: number;
  geom?: string;
  field_id?: string;
  sensors?: string[];
  points?: string;
  interest_layer?: '50' | '100' | '150'
};

export type Zone = Omit<RawZone, "points"> & {points: google.maps.LatLngLiteral[]}

export type ZoneSoilProfile = {
  wpoint_50: number;
  wpoint_100: number;
  wpoint_150: number;
  fcapacity_50: number;
  fcapacity_100: number;
  fcapacity_150: number;
  saturation_50: number;
  saturation_100: number;
  saturation_150: number;
  interest_layer: '50' | '100' | '150'
};

export const fetchZone = async (config?: AxiosRequestConfig) => {
  return axiosInstance
    .get<RawZone[]>(`api/v2/zone/`,
      {...reqConfig, ...config}
    )
    .then(({data}) => {
      let zones = data.map(zone => {
        let points: google.maps.LatLngLiteral[] = []
        if (zone.points) {
          let coordinates = JSON.parse(zone.points).coordinates[0] as number[][]
          coordinates.forEach(([lng,lat]) => points.push({lat, lng}) )
        }
        return {...zone, points}
      })
     return Promise.resolve(zones)
    })
    .catch((err) => Promise.reject(err.response.data.err));
};

export const insertZone = async (
  fieldId: string,
  data: Omit<RawZone, "fieldId">
) => {
  return axiosInstance
    .post(`api/v2/zone/${fieldId}`, data, reqConfig)
    .then((res) => Promise.resolve(res.data.data))
    .catch((err) => Promise.reject(err.response.data.err));
};

export const updateZone = async (id: string, data: Omit<RawZone, "id">) => {
  return axiosInstance
    .put(`api/v2/zone/${id}`, data, reqConfig)
    .then((res) => Promise.resolve(res.data.data))
    .catch((err) => Promise.reject(err.response.data.err));
};

export const deleteZone = async (id: string) => {
  return axiosInstance
    .delete(`api/v2/zone/${id}`, reqConfig)
    .catch((err) => Promise.reject(err.response.data.err));
};

export const fetchWPoints = async (sensorId: string) => {
  return axiosInstance
    .get<{ data: ZoneSoilProfile[] }>(
      `api/v2/zone/wpoint/${sensorId}`,
      reqConfig
    )
    .then((res) => Promise.resolve(res.data.data))
    .catch((err) => Promise.reject(err.response.data.err));
};
