import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Table,
  Button,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import Widget from "../../components/Widget/index.tsx";
import { fetchZone, deleteZone } from "../../actions/v2/zone";
import s from "./ZoneManagement.module.scss";
import { connect } from "react-redux";
import FormInput from "./FormInput/FormInput";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchFields } from "../../actions/v2/field";
import ButtonDropdownMenu from "../../components/dropdown-menu.tsx";

class ZoneManagement extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  state = {
    fields: [],
    zones: [],
    choosenZone: {},
    tableloading: false,
    modalOpen: false,
    dropdownOpen: false,
    deleteModalOpen: false,
    editModalOpen: false,
    options: {
      position: "top-right",
      autoClose: 5000,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
    },
  };

  deleteZone(e) {
    deleteZone(this.state.choosenZone.id)
      .then((data) => {
        this.loadTable();
        this.deleteToggle();
        toast.success(
          <div>
            <span className="glyphicon glyphicon-info-sign ml-2 mr-2" />
            Delete zone {this.state.choosenZone.zonename} was successful
          </div>,
          { ...this.state.options }
        );
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          <div>
            <span className="glyphicon glyphicon-info-sign ml-2 mr-2" />
            Delete zone {this.state.choosenZone.zonename} was failed
          </div>,
          { ...this.state.options }
        );
      });
  }

  toggleModal() {
    this.setState({ modalOpen: !this.state.modalOpen });
  }

  deleteToggle(zone) {
    if (zone) this.setState({ choosenZone: zone });
    this.setState({ deleteModalOpen: !this.state.deleteModalOpen });
  }

  editToggle(zone) {
    if (zone) this.setState({ choosenZone: zone });
    this.setState({ editModalOpen: !this.state.editModalOpen });
  }

  loadTable() {
    this.setState({ tableloading: true });

    this.props.dispatch(fetchFields());
    fetchZone()
      .then((zones) => {
        let fields = new Set();
        for (let zone of zones) if (zone.fieldname) fields.add(zone.fieldname);
        fields = Array.from(fields.values());
        this.setState({ fields });
        this.setState({ zones });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(this.setState({ tableloading: false }));
  }

  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  componentDidMount() {
    this.loadTable();
  }

  dropDownSelect = (item) => {
    let val = item.target.innerText;
    if (val === 'All Fields') val = ''
    this.setState({ choosenField: val });
  };

  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.deleteToggle = this.deleteToggle.bind(this);
    this.editToggle = this.editToggle.bind(this);
    this.deleteZone = this.deleteZone.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.userName = localStorage.getItem("userName");
    this.tableKeys = [
      { key: "zonename", label: "Zone" },
      { key: "fieldname", label: "Field Name" },
      { key: "farmname", label: "Farm Name" },
      { key: "croptype", label: "Crop Type" },
      { key: "soiltype_25", label: "Soil Type (25cm)" },
      { key: "soiltype_75", label: "Soil Type (75cm)" },
      { key: "soiltype_125", label: "Soil Type (125cm)" },
    ];
  }

  render() {
    const { deleteModalOpen } = this.state;
    return (
      <div className={s.root}>
        {/* Delete Zone Modal*/}
        <Modal isOpen={deleteModalOpen} toggle={() => this.deleteToggle()}>
          <ModalHeader toggle={() => this.deleteToggle()}>
            Zone Deletion
          </ModalHeader>
          <ModalBody>
            <span className="text-danger mr-2 ml-2 mt-2 mb-2 text-center">
              <span className="glyphicon glyphicon-warning-sign mr-2" />
              Are you sure you want to delete this zone ?
            </span>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.deleteZone} outline>
              Delete
            </Button>{" "}
            <Button color="primary" onClick={() => this.deleteToggle()} outline>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        {/* Edit Zone Modal */}
        <Modal
          isOpen={this.state.editModalOpen}
          toggle={() => this.editToggle()}
          size="lg"
        >
          <ModalHeader toggle={() => this.editToggle()}>
            Edit and View Zone
          </ModalHeader>
          <ModalBody>
            <div className="ml-5 mr-5">
              <FormInput
                userName={this.userName}
                modalClose={() => {
                  this.setState({ editModalOpen: false });
                }}
                tableReload={() => {
                  this.loadTable();
                }}
                chosenZone={this.state.choosenZone}
                zones={this.state.zones}
                dispatch={this.props.dispatch}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.editToggle()} outline>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        {/* Register Zone Modal */}
        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.toggleModal}
          size="lg"
        >
          <ModalHeader toggle={this.toggleModal}>Zone Registration</ModalHeader>
          <ModalBody>
            <div className="ml-5 mr-5">
              <FormInput
                userName={this.userName}
                modalClose={() => {
                  this.setState({ modalOpen: false });
                }}
                tableReload={() => {
                  this.loadTable();
                }}
                zones={this.state.zones}
                dispatch={this.props.dispatch}
              />
            </div>
          </ModalBody>
        </Modal>

        {/* Heading Row*/}
        <Row className={s.title}>
          <Col sm={12} md={9}>
            <h1 className="mb-lg">Irrigation Zone Management</h1>
          </Col>
          <Col sm={12} md={3}>
            <Button
              onClick={this.toggleModal}
              color="primary"
              className="floar-right"
            >
              <span className="glyphicon glyphicon-new-window mr-2 mt-1"></span>
              Create New Irrigation Zone
            </Button>
          </Col>
        </Row>
        {/* Table Row */}
        <Row>
          <Col sm={12} md={11}>
            <Widget
              className={s.shadow}
              title={<h3> Zone List</h3>}
              settings
              close
            >
              {/*farm dropdown list*/}
              <ButtonDropdownMenu className="float-right">
                <DropdownToggle color="primary" caret outline>
                  {this.state.choosenField || "Choose A Field"}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={this.dropDownSelect}>All Fields</DropdownItem>
                  {this.props.fields && Object.keys(this.props.fields).length > 0 &&
                    Object.keys(this.props.fields).map((farm, i) => (
                      <React.Fragment key={`dropdownMenu${i}`}>
                        <DropdownItem className="font-weight-bold">{farm.toUpperCase()}</DropdownItem>
                        {this.props.fields[farm] &&
                          this.props.fields[farm].map((field, j) => (
                            <DropdownItem onClick={this.dropDownSelect} key={j}>
                              {field.field_name}
                            </DropdownItem>
                          ))}
                      </React.Fragment>
                    ))}
                </DropdownMenu>
              </ButtonDropdownMenu>
              {/* <ButtonDropdown
                className={s.dropDown}
                isOpen={this.state.dropdownOpen}
                toggle={this.toggleDropdown}
              >
                <DropdownToggle color="primary" caret outline>
                  {this.state.choosenField || "Choose A Field"}
                </DropdownToggle>
                <DropdownMenu>
                  {this.state.fields.map((field, index) => (
                    <DropdownItem onClick={this.dropDownSelect} key={index}>
                      {field}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </ButtonDropdown> */}

              {this.state.tableloading ? (
                <Spinner color="primary" className={s.center} />
              ) : (
                <div>
                  <Table borderless className={s.mainTable}>
                    <thead>
                      <tr>
                        <th className="hidden-sm-down">#</th>
                        {this.tableKeys.map((key, index) => (
                          <th key={index}>{key.label}</th>
                        ))}
                        <th>actions</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.zones
                        .filter(
                          (zone) =>
                            zone.fieldname === this.state.choosenField ||
                            !this.state.choosenField
                        )
                        .map((row, index) => {
                          return (
                            <tr key={index + 1}>
                              <td>{index + 1}</td>
                              {this.tableKeys.map((key, index) => (
                                <td key={index}>{row[key.key]}</td>
                              ))}
                              <td>
                                <Button
                                  onClick={() => {
                                    this.editToggle(row);
                                  }}
                                  className="mr-3"
                                  size="sm"
                                  color="success"
                                  outline
                                >
                                  <span className="glyphicon glyphicon-edit mr-1 mt-1"></span>
                                  Edit
                                </Button>
                                <Button
                                  onClick={() => {
                                    this.deleteToggle(row);
                                  }}
                                  className="mr-3"
                                  size="sm"
                                  color="danger"
                                  outline
                                >
                                  <span className="glyphicon glyphicon-trash mr-1 mt-1"></span>
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  <div className="row d-flex justify-content-center">
                    <Pagination aria-label="Page navigation example">
                      <PaginationItem>
                        <PaginationLink first />
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink previous />
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink>1</PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink next />
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink last />
                      </PaginationItem>
                    </Pagination>
                  </div>
                </div>
              )}
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { fields: state.field.farmsWithFields };
}

export default connect(mapStateToProps)(ZoneManagement);
