import React from "react";

import FieldManagement from "../fieldmanagement";
import SensorManagement from "../sensormanagement";
import ZoneManagement from "../zonemanagement";
import Profile from "../profile";

import Layout from "../../components/Layout";
import { IRoute, ISideBarMenu } from "aquaterra-types";
import { RecordTable } from "../admin/record";
import FormulaTable from "../admin/formula";
import { isAdmin } from "../../actions/cognito";
import Subscription from "../subscription";
import Checkout from "../checkout";
// import Dashboard from "src/pages/dashboard";
import DashboardV2 from "../dashboard/v2";
import DashboardV3 from "src/pages/dashboard/HomePageDashboard";

const Routes: IRoute[] = [
  { path: "/app/Home", component: DashboardV3 },
  // { path: "/app/Home", component: DashboardV2 },
  { path: "/app/Management/SensorManagement", component: SensorManagement },
  { path: "/app/Management/FieldManagement", component: FieldManagement },
  { path: "/app/Management/ZoneManagement", component: ZoneManagement },
  { path: "/app/profile", component: Profile },
  { path: "/fieldwork-admin/prediction", component: RecordTable },
  { path: "/fieldwork-admin/formula", component: FormulaTable },
  { path: "/app/subscription", component: Subscription },

  { path: "/app/checkout", component: Checkout },

  { path: "/app/Home/Dashboard", component: DashboardV2 },
  // { path: "/app/Home/OldDashboard", component: Dashboard }
];

const UserSideBarMenus: ISideBarMenu[] = [
  {
    header: "Dashboard",
    headerLink: "/app/Home/Dashboard",
    glyph: "glyphicon-dashboard",
  },
  // {
  //   header: "Old Dashboard",
  //   headerLink: "/app/Home/oldDashboard"
  //   , glyph: "glyphicon-dashboard"
  // },
  {
    header: "Management",
    headerLink: "/app/Management",
    glyph: "glyphicon-th-list",
    childrenLinks: [
      // {
      //   name: "My Gateways",
      //   link: "/app/Management/GatewayManagement",
      // },
      {
        name: "My Farms and Fields",
        link: "/app/Management/FieldManagement",
      },
      {
        name: "My Irrigation Zones",
        link: "/app/Management/ZoneManagement",
      },
      {
        name: "My Sensors",
        link: "/app/Management/SensorManagement",
      },
    ],
  },
];

const AdminSideBarMenu: ISideBarMenu = {
  header: "Fieldwork",
  headerLink: "/fieldwork-admin",
  glyph: "glyphicon-folder-close",
  childrenLinks: [
    { name: "Formula Table", link: "/fieldwork-admin/formula" },
    { name: "Prediction Data", link: "/fieldwork-admin/prediction" },
  ],
};

export const Home = () => {
  return (
    <Layout
      routes={Routes}
      sidebarMenu={
        !isAdmin() ? UserSideBarMenus : [...UserSideBarMenus, AdminSideBarMenu]
      }
    />
  );
};
