import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Col,
  Button,
  Table,
} from "reactstrap";
import { ProgressBar } from "react-bootstrap";
import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useFieldMoistureDataContext } from "../../hooks/useFieldMoistureDataContext";
import DatePicker from "react-datepicker";
import {
  fetchMoistureDataByField,
  MoistureDataContent,
} from "src/actions/v2/moisture";
import { isSensorMultilayer } from "src/actions/v2/sensor";
import { EMPTY_FIELD_DATA, Field } from "src/actions/v2/field";
import {
  EvaporationDataEntryType,
  fetchEvaporation,
} from "src/actions/v2/evaporation";
import ReportMap from "../DisplayMap/ReportMap";
import { googleMapAPI } from "src/config/secret";
import s from "../../Dashboard.module.scss";

import { Alert, AlertTitle, Snackbar } from "@mui/material";
import { withRouter } from "react-router";
import { IComponentType } from "src/types";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { Zone } from "src/actions/v2/zone";
import { useAppSelector } from "src/store";
import { generateReport } from "../../actions/downloadReport";

const DEPTH_COLOR = ["#8884d8", "#82ca9d", "#fc3903"];

type DownloadReportModalProps = {
  isOpen: boolean;
  toggle: () => void;
};

const DownloadReportModal: IComponentType<DownloadReportModalProps> = ({
  isOpen,
  toggle,
  location,
}) => {
  const { zones } = useFieldMoistureDataContext();
  const username = useAppSelector((state) => state.auth.userName);
  const [fetching, setFetching] = useState(false);
  const chosenField = location.state?.field;
  const [moistureData, setMoistureData] = useState<{
    [sensorId: string]: MoistureDataContent[];
  }>({});
  const [evpData, setEvpData] = useState<EvaporationDataEntryType[]>([]);

  const [start, setStart] = useState<Date | undefined | null>(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  ); // 7 days before
  const [end, setEnd] = useState<Date | undefined | null>(new Date());
  const [selectedZones, selectZone] = useState<{ [id: string]: boolean }>({});
  const [selectedSensors, selectSensor] = useState<{ [id: string]: boolean }>(
    {}
  );

  const mapRef = useRef<any>(null);

  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setMoistureData({});
      setEvpData([]);

      selectZone({});

      setStart(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
      setEnd(new Date());
    }
  }, [isOpen]);

  useEffect(() => {
    if (zones.length > 0) {
      let zoneSelection = Object.fromEntries([
        ...zones.map((zone) => [zone.id, false]),
        ["Others", false],
      ]);
      selectZone(zoneSelection);
    }
  }, [zones]);

  useEffect(() => {
    if (!moistureData) {
      selectSensor({});
      return;
    }

    let sensorSelection = Object.fromEntries(
      Object.keys(moistureData).map((s) => [s, false])
    );

    selectSensor(sensorSelection);
  }, [moistureData]);

  const [showZoneWarning, setShowZoneWarning] = useState(false);
  const [showSensorWarning, setShowSensorWarning] = useState(false);
  const fetchData = () => {
    if (
      !chosenField?.field_id ||
      chosenField.field_id === EMPTY_FIELD_DATA.field_id
    )
      return;
    if (!start || !end) return;

    setFetching(true);

    let fetchMoistureData = fetchMoistureDataByField(chosenField.field_id, {
      params: { start: start.getTime() / 1000, end: end.getTime() / 1000 },
    });

    let fetchEvpData = fetchEvaporation(chosenField.field_id, { start, end });

    Promise.all([fetchMoistureData, fetchEvpData])
      .then(([fieldMoistureData, evpData]) => {
        setMoistureData(
          Object.fromEntries(
            Object.entries(fieldMoistureData).map(([senosrId, d]) => [
              senosrId,
              d.moisture,
            ])
          )
        );

        setEvpData(evpData);
      })
      .catch((e) => console.log(e))
      .finally(() => setFetching(false));
  };

  const sensorsWithinSelectedZones = useMemo(() => {
    let result: string[] = [];
    zones
      .filter((zone) => selectedZones[zone.id!])
      .forEach((z) => {
        result = result.concat(
          z.sensors?.filter((s) => Object.keys(moistureData).includes(s)) || []
        );
      });

    if (selectedZones["Others"]) {
      result = result.concat(
        Object.keys(moistureData).filter((s) =>
          zones.every((z) => !z.sensors?.includes(s))
        )
      );
    }

    return result;
  }, [selectedZones, moistureData, zones]);

  const [showProgressModal, setShowProgressModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [completed, setCompleted] = useState(false);
  async function downloadReport(
    evpRef: HTMLDivElement,
    moistureRefs: HTMLDivElement[]
  ) {
    setShowProgressModal((prev) => true);

    let input = {
      username,
      map: mapRef.current as HTMLDivElement,
      lineChart: moistureRefs,
      evp: evpRef,
      chosenField: chosenField!.field_name!,
      chosenFarm: chosenField!.farm_name!,
      zones: zones
        .filter((zone) => selectedZones[zone.id!])
        .map((zone) => ({ zoneName: zone.zonename! })) || [],
      startDate: start!,
      endDate: end!,
      sensorOrNot: Object.entries(moistureData).length > 0,
      callback: { setCompletedProgress: setProgress }
    };

    await generateReport(input)

    setCompleted(true)
  }

  if (!chosenField) return <></>;

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Generate a Report</ModalHeader>

      <ModalBody className="px-5">
        <Row>
          <Col sm={12} md={3}>
            Start Date:
          </Col>
          <Col sm={12} md={9}>
            <DatePicker
              selected={start}
              onChange={setStart}
              selectsStart
              startDate={start}
              endDate={end}
              placeholderText="Start Date"
              maxDate={new Date()}
              dateFormat={"dd/MM/yyyy"}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={3}>
            End Date:
          </Col>
          <Col sm={12} md={5}>
            <DatePicker
              selected={end}
              onChange={setEnd}
              selectsEnd
              startDate={start}
              endDate={end}
              minDate={start}
              placeholderText="End Date"
              maxDate={new Date()}
              dateFormat={"dd/MM/yyyy"}
            />
          </Col>
          <Col sm={12} md={4}>
            <Button
              onClick={fetchData}
              color="primary"
              className="floar-right mr-1"
              outline
              disabled={fetching}
            >
              {fetching ? "Loading Data..." : "Confirm"}
            </Button>
          </Col>
        </Row>
        {Object.keys(moistureData).length > 0 && (
          <>
            {/* Report Map */}
            <Row className="mt-5">
              <div className={s.map}
                ref={mapRef}>
                <ReportMap
                  containerElement={<div style={{ height: "inherit" }} />}
                  mapElement={<div style={{ height: "inherit" }} />}
                  googleMapURL={googleMapAPI}
                  loadingElement={
                    <div style={{ height: "inherit", width: "inherit" }} />
                  }
                  field={chosenField as Field}
                  zones={zones.filter((zone) => selectedZones[zone.id!])}
                  sensors={Object.fromEntries(
                    Object.entries(moistureData).map(
                      ([sensorId, sensorMoisture]) => [
                        sensorId,
                        {
                          lat: sensorMoisture[0].point.coordinates[1],
                          lng: sensorMoisture[0].point.coordinates[0],
                        },
                      ]
                    )
                  )}
                />
              </div>
            </Row>
            {/* Zone Selection */}
            <Row className="mt-5">
              <div className={s.zoneSelection}>
                <Alert variant="outlined" severity="info">
                  <AlertTitle>Irrigation Zone Selection</AlertTitle>
                  Please select one or more zones below:
                  <hr />
                  <Table borderless size="sm">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Zone</th>
                        <th>Crop Type</th>
                        <th>Soil Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {zones.map((zone, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedZones[zone.id!]}
                              onChange={() => {
                                if (selectedZones[zone.id!]) {
                                  // if zone has been selected, uncheck the selection
                                  selectZone((prev) => ({
                                    ...prev,
                                    [zone.id!]: false,
                                  }));
                                } else if (
                                  Object.entries(selectedZones).filter(
                                    ([zoneId, zoneSelected]) =>
                                      zoneId !== "Others" && zoneSelected
                                  ).length < 2
                                ) {
                                  selectZone((zoneChecked) => ({
                                    ...zoneChecked,
                                    [zone.id!]: true,
                                  }));
                                } else setShowZoneWarning(true);
                              }}
                            />
                          </td>
                          <td>{zone.zonename}</td>
                          <td>{zone.croptype}</td>
                          <td>{zone.soiltype_25}</td>
                        </tr>
                      ))}
                      <tr>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedZones["Others"]}
                            onChange={() =>
                              selectZone((zoneChecked) => ({
                                ...zoneChecked,
                                Others: !zoneChecked["Others"],
                              }))
                            }
                          />
                        </td>
                        <th>
                          Other Sensors
                          <br />
                          (excluded from zones above)
                        </th>
                      </tr>
                    </tbody>
                  </Table>
                </Alert>
                <Snackbar
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  open={showZoneWarning}
                  autoHideDuration={3000}
                  onClose={() => setShowZoneWarning(false)}
                >
                  <Alert
                    onClose={() => setShowZoneWarning(false)}
                    variant="filled"
                    severity="warning"
                  >
                    No more than 2 zones excluding{" "}
                    <strong>'Other Sensors'</strong>, the upper limit has been
                    reached
                  </Alert>
                </Snackbar>
              </div>
            </Row>
            {/* Evaporation Chart */}
            <Row className="mt-5">
              <EvaporationChart data={evpData} />
            </Row>

            {/* Sensor Selection */}
            {sensorsWithinSelectedZones.length > 0 && (
              <Row className="mt-5">
                <Col>
                  <Alert severity="info">
                    <AlertTitle>Sensor Selection</AlertTitle>
                    Please seelct no more than 12 sensors from below:
                    <hr />
                    {sensorsWithinSelectedZones.length < 12 ? (
                      <div>
                        <input
                          type="checkbox"
                          onChange={() => {
                            let allSelected = Object.values(
                              selectedSensors
                            ).every((sensorSelected) => sensorSelected);
                            selectSensor((prev) => {
                              return Object.fromEntries(
                                Object.keys(prev).map((k) => [k, !allSelected])
                              );
                            });
                          }}
                          checked={Object.values(selectedSensors).every(
                            (sensorSelected) => sensorSelected
                          )}
                        />
                        Select All
                      </div>
                    ) : (
                      <>
                        <div>
                          <input
                            type="checkbox"
                            onChange={() => {
                              let allSelected = Object.entries(
                                selectedSensors
                              ).every(([sensorId, sensorSelected]) => {
                                if (
                                  !sensorsWithinSelectedZones.includes(sensorId)
                                )
                                  return true;
                                if (
                                  sensorsWithinSelectedZones.indexOf(
                                    sensorId
                                  ) >= 12
                                )
                                  return true;
                                return sensorSelected;
                              });
                              selectSensor((prev) => ({
                                ...prev,
                                ...Object.fromEntries(
                                  sensorsWithinSelectedZones.map((v, i) => [
                                    v,
                                    i < 12 && !allSelected,
                                  ])
                                ),
                              }));
                            }}
                            checked={Object.entries(selectedSensors).every(
                              ([sensorId, sensorSelected]) => {
                                if (
                                  !sensorsWithinSelectedZones.includes(sensorId)
                                )
                                  return true;
                                if (
                                  sensorsWithinSelectedZones.indexOf(
                                    sensorId
                                  ) >= 12
                                )
                                  return true;
                                return sensorSelected;
                              }
                            )}
                          />
                          Select the first 12 sensors
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            onChange={() => {
                              let allSelected = Object.entries(
                                selectedSensors
                              ).every(([sensorId, sensorSelected]) => {
                                if (
                                  !sensorsWithinSelectedZones.includes(sensorId)
                                )
                                  return true;
                                if (
                                  sensorsWithinSelectedZones.indexOf(sensorId) <
                                  sensorsWithinSelectedZones.length - 12
                                )
                                  return true;
                                return sensorSelected;
                              });
                              selectSensor((prev) => ({
                                ...prev,
                                ...Object.fromEntries(
                                  sensorsWithinSelectedZones.map(
                                    (v, i, arr) => [
                                      v,
                                      i >= arr.length - 12 && !allSelected,
                                    ]
                                  )
                                ),
                              }));
                            }}
                            checked={Object.entries(selectedSensors).every(
                              ([sensorId, sensorSelected]) => {
                                if (
                                  !sensorsWithinSelectedZones.includes(sensorId)
                                )
                                  return true;
                                if (
                                  sensorsWithinSelectedZones.indexOf(sensorId) <
                                  sensorsWithinSelectedZones.length - 12
                                )
                                  return true;
                                return sensorSelected;
                              }
                            )}
                          />
                          Select the last 12 sensors
                        </div>
                      </>
                    )}
                  </Alert>
                  <Snackbar
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    open={showSensorWarning}
                    autoHideDuration={6000}
                    onClose={() => setShowSensorWarning(false)}
                  >
                    <Alert
                      onClose={() => setShowSensorWarning(false)}
                      variant="filled"
                      severity="warning"
                    >
                      No more than 12 sensors, the upper limit has been reached
                    </Alert>
                  </Snackbar>
                </Col>
              </Row>
            )}
            <Row className="mt-5">
              <Col className={s.ChartContainer}>
                {sensorsWithinSelectedZones.map((sensorId, i) => {
                  let data = moistureData[sensorId];
                  return (
                    <React.Fragment key={i}>
                      <div className={s.reportTitle}>
                        <input
                          type="checkbox"
                          checked={selectedSensors[sensorId]}
                          onChange={(e) => {
                            selectSensor((prev) => {
                              return {
                                ...prev,
                                [sensorId]: !prev[sensorId],
                              };
                            });
                          }}
                        />
                        Sensor ID: {sensorId} -{" "}
                        {zones.find(({ sensors }) =>
                          sensors?.includes(sensorId)
                        )?.zonename || "No Zone"}
                      </div>
                      <MoistureChart data={data} sensorId={sensorId} />;
                    </React.Fragment>
                  );
                })}
              </Col>
            </Row>
            <Row className="mt-5">
              <Button
                onClick={() => {
                  setConfirmModalOpen(true);
                }}
                color="primary"
                className={s.centerButton}
                outline
              >
                Generate
              </Button>
            </Row>
          </>
        )}
        {isConfirmModalOpen && (
          <ConfirmModal
            isOpen={isConfirmModalOpen}
            toggle={() => setConfirmModalOpen((prev) => !prev)}
            evpData={evpData}
            moistureData={Object.fromEntries(
              sensorsWithinSelectedZones
                .filter((sensorId) => selectedSensors[sensorId])
                .map((sensorId) => [sensorId, moistureData[sensorId]])
            )}
            zones={zones}
            onDownload={downloadReport}
          />
        )}

        <ProgressModal
          isOpen={showProgressModal}
          toggle={() => setShowProgressModal(prev => !prev)}
          progress={progress}
          completed={completed}
        />
      </ModalBody>
    </Modal>
  );
};

export default withRouter(DownloadReportModal);

const EvaporationChart = forwardRef<
  ResponsiveContainer,
  { data: EvaporationDataEntryType[] }
>(({ data }, ref) => (
  <ResponsiveContainer height={300} width="100%" ref={ref}>
    <BarChart
      data={data.map(({ date, evaporation }) => {
        return {
          date: new Date(date).toDateString(),
          Evaporation: evaporation.toFixed(2),
        };
      })}
      margin={{ top: 50, right: 30, bottom: 5, left: 30 }}
    >
      <CartesianGrid strokeDasharray="3 3" vertical={false} />
      <XAxis dataKey="date" />
      <YAxis yAxisId="left" orientation="left" stroke="#5c5c5c" unit="mm" />
      <Bar yAxisId="left" dataKey="Evaporation" fill="#8884d8" />
    </BarChart>
  </ResponsiveContainer>
));

const MoistureChart = forwardRef<
  ResponsiveContainer,
  { data: MoistureDataContent[]; sensorId: string }
>(({ data, sensorId }, ref) => {
  const isSensorMultiLayer = useMemo(
    () => isSensorMultilayer(sensorId),
    [sensorId]
  );
  const keyFor10cmLayer = isSensorMultiLayer ? "Moisture 10 cm" : "Moisture";
  const keyFor100cmLayer = "Moisture 100 cm";
  const keyFor150cmLayer = "Moisture 150 cm";

  return (
    <ResponsiveContainer height={300} width="100%" ref={ref}>
      <LineChart
        data={data.map(
          ({
            time,
            cap50_percent,
            cap100_percent,
            cap150_percent,
            temperature,
          }) => {
            return {
              Time: new Date(time).toLocaleString("en-US", {
                weekday: "short",
                month: "short",
                day: "numeric",
                year: "numeric",
              }),
              [keyFor10cmLayer]: cap50_percent.toFixed(2),
              [keyFor100cmLayer]: cap100_percent.toFixed(2),
              [keyFor150cmLayer]: cap150_percent.toFixed(2),
              Temperature: temperature,
            };
          }
        )}
        margin={{ top: 50, right: 30, bottom: 5, left: 30 }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis
          dataKey="Time"
          height={80}
          tick={{ fontSize: 13 }}
          interval={Math.trunc(data.length / 4)}
        />
        <YAxis
          yAxisId="left"
          orientation="left"
          stroke="#5c5c5c"
          unit="%"
          width={80}
          label={{
            value: "Vol. Moisture",
            angle: -90,
            position: "insideLeft",
            offset: 10,
            fill: "#7d7c7c",
          }}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          unit="℃"
          tick={{ fontSize: 13 }}
          label={{
            value: "Temperature",
            angle: 90,
            position: "insideRight",
            offset: -10,
            fill: "#7d7c7c",
          }}
        />
        <Legend verticalAlign="top" margin={{ bottom: 50 }} />
        <Line
          yAxisId="left"
          dataKey={keyFor10cmLayer}
          stroke={DEPTH_COLOR[0]}
          strokeWidth={2}
          dot={false}
        />
        {isSensorMultiLayer && (
          <Line
            yAxisId="left"
            dataKey={keyFor100cmLayer}
            stroke={DEPTH_COLOR[1]}
            strokeWidth={2}
            dot={false}
          />
        )}
        {isSensorMultiLayer && (
          <Line
            yAxisId="left"
            dataKey={keyFor150cmLayer}
            stroke={DEPTH_COLOR[2]}
            strokeWidth={2}
            dot={false}
          />
        )}
        <Line
          dataKey={"Temperature"}
          yAxisId={"right"}
          type={"monotone"}
          stroke={"#f3c363"}
          strokeWidth={2}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
});

const ConfirmModal: React.FC<
  DownloadReportModalProps & {
    evpData: EvaporationDataEntryType[];
    moistureData: {
      [sensorId: string]: MoistureDataContent[];
    };
    zones: Zone[];
    onDownload: (
      evpRef: HTMLDivElement,
      moistureRefs: HTMLDivElement[]
    ) => void;
  }
> = ({ evpData, moistureData, zones, onDownload, ...props }) => {
  const evpRef = useRef<HTMLDivElement>(null);
  const moistureRefs = useRef<HTMLDivElement[]>([]);

  const sensorGroups = useMemo(() => {
    let sensorGroups = [];
    const stride = 3;
    let selectedSensors = Object.keys(moistureData);
    for (let i = 0; i < selectedSensors.length; i += stride) {
      sensorGroups.push(selectedSensors.slice(i, i + stride));
    }
    return sensorGroups;
  }, [moistureData]);

  return (
    <Modal {...props} size="lg">
      <ModalHeader toggle={props.toggle}>
        Confirm Selected Sensor Graphs
      </ModalHeader>
      <ModalBody>
        <div className="mb-3">
          <div className={s.ChartContainer} ref={evpRef} >
            <EvaporationChart data={evpData} />
          </div>
        </div>

        {Object.entries(moistureData).length > 0 ? (
          <div
            style={{ display: "flex", flexDirection: "column", rowGap: "1rem" }}
          >
            {sensorGroups.map((group, i) => (
              <div
                key={`group${i}`}
                ref={(e) => {
                  if (e && !moistureRefs.current.includes(e)) {
                    moistureRefs.current.push(e);
                  }
                }}
                className={s.ChartContainer}
              >
                {group.map((sensor, i) => (
                  <div key={i}>
                    <div className={s.reportTitle}>
                      Sensor ID: {sensor} -{" "}
                      {zones.find(({ sensors }) => sensors?.includes(sensor))
                        ?.zonename || "No Zone"}
                    </div>
                    <MoistureChart
                      data={moistureData[sensor]}
                      sensorId={sensor}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        ) : (
          <div>
            No sensors selected
            <br />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          // onClick={this.toggleNested}
          onClick={() => onDownload(evpRef.current!, moistureRefs.current!)}
          color="primary"
          className={s.centerButton}
          outline
        >
          Download
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const ProgressModal: React.FC<DownloadReportModalProps & { progress: number, completed: boolean }> = ({ progress, completed, ...props }) => (
  <Modal
    {...props}
  >
    <ModalHeader toggle={props.toggle}>
      Downloading
    </ModalHeader>
    <ModalBody>
      {completed ? (
        <Alert severity="success">
          <AlertTitle>Download Successfully</AlertTitle>
        </Alert>
      ) : (
        <div>
          <p>The report is downloading... <br />
            Please wait for about 1 min</p>
          <br />
          <ProgressBar
            animated
            now={progress}
            label={progress}
          />
        </div>
      )}
    </ModalBody>
  </Modal>
)