import html2canvas from "html2canvas";
import { logo, logo2 } from "src/constants/logo";
import { jsPDF } from "jspdf";

var completedProgress = 10;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function setCompletedProgress(completed: number) {
  completedProgress = completed;
}
export function getCompletedProgress() {
  return completedProgress;
}

export async function generateReport(input: {
  username: string;
  map: HTMLDivElement;
  lineChart: (HTMLDivElement)[];
  evp: HTMLDivElement;
  chosenField: string;
  chosenFarm: string;
  zones: { zoneName: string }[];
  startDate: Date;
  endDate: Date;
  sensorOrNot: boolean;
  callback?: { setCompletedProgress: (progress: number) => void };
}) {
  const monthTran: { [mon: string]: string } = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };

  let doc = new jsPDF();
  let pageNumber = 1;

  let ans = String(input.startDate).split(" ");
  let stDate = `${ans[2]}/${monthTran[ans[1]]}/${ans[3]}`;
  let ans2 = String(input.endDate).split(" ");
  let edDate = `${ans2[2]}/${monthTran[ans2[1]]}/${ans2[3]}`;

  input.callback?.setCompletedProgress(10);

  doc.addImage(logo2, "png", 5, 5, 18, 18);
  doc.setFontSize(10);
  doc.setTextColor("Gray");
  // Powered By AquaTerra
  doc.text("Powered by", 98, 25);
  doc.addImage(logo, "png", 118, 20, 22, 7);
  // Date
  doc.setFontSize(12);
  doc.setTextColor("Black");
  let date = new Date();
  let year = date.getFullYear();
  let month: number | string = date.getMonth() + 1;
  let day: number | string = date.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  let nowData = day + "/" + month + "/" + year;
  doc.text("Report Generated: ", 145, 10);
  doc.setTextColor(0, 120, 255);
  doc.text(nowData, 180, 10);
  doc.setTextColor("Black");
  doc.text("From: ", 145, 15);
  doc.setTextColor(0, 120, 255);
  doc.text(stDate, 180, 15);
  doc.setTextColor("Black");
  doc.text("To: ", 145, 20);
  doc.setTextColor(0, 120, 255);
  doc.text(edDate, 180, 20);
  doc.setTextColor("Black");
  doc.text("Generated By: ", 145, 25);
  doc.setTextColor(0, 120, 255);
  doc.text(input.username, 180, 25);
  // line
  doc.setLineWidth(1);
  doc.line(5, 30, 205, 30);
  let tail = 30;

  const node = input.map;
  let canvas = await html2canvas(node, { useCORS: true });

  const contentWidth = canvas.width;
  const contentHeight = canvas.height;
  const wth = contentWidth / contentHeight;
  const imgData = canvas.toDataURL("image/png");
  tail = tail + 5;
  doc.addImage(imgData, "png", 5, tail, 80 * wth, 80);
  doc.rect(80 * wth + 7, tail, 193 - 80 * wth, 80);
  // Farm Name
  doc.setTextColor("Black");
  doc.text("Farm Name: ", 80 * wth + 10, 40);
  doc.setTextColor("Green");
  doc.text(input.chosenFarm, 80 * wth + 40, 40);
  // Field Name
  doc.setTextColor("Black");
  doc.text("Field Name: ", 80 * wth + 10, 45);
  doc.setTextColor("Green");
  doc.text(input.chosenField, 80 * wth + 40, 45);
  //------------------Zone-----------------------
  // zone: zoneforReport,
  // zoneName: zoneSelected[0]["zonename"]
  // cropType: zoneSelected[0]["croptype"],
  // soilType: zoneSelected[0]["soiltype_25"],
  // wiltingPoint: zoneSelected[0]["wpoint_50"],
  // fieldCapacity: zoneSelected[0]["fcapacity_50"],
  // saturation: zoneSelected[0]["saturation_50"],
  let zoneTail = 45;
  const reportZoneColor = [
    [189, 55, 122],
    [255, 127, 80],
  ];
  input.zones.forEach((zone: any, index: number) => {
    if (zone.cropType) {
      doc.setDrawColor(160, 160, 160); // grey #A0A0A0
      doc.setLineWidth(0.5);
      doc.setLineDashPattern([3.5, 1], 0);
      doc.line(80 * wth + 10, zoneTail + 2.85, 197, zoneTail + 2.85);

      // Zone Name
      zoneTail += 8;
      doc.setTextColor("Black");
      doc.text("Zone Name: ", 80 * wth + 10, zoneTail);
      doc.setTextColor("Green");
      doc.text(zone.zoneName, 80 * wth + 40, zoneTail);

      // Circle behind Zone Name
      // doc.setLineWidth(1)
      // doc.setDrawColor(0)
      doc.setFillColor(
        reportZoneColor[index][0],
        reportZoneColor[index][1],
        reportZoneColor[index][2]
      );
      doc.circle(80 * wth + 37, zoneTail - 2, 2, "F");

      // Crop Type
      zoneTail += 5;
      doc.setTextColor("Black");
      doc.text("Crop Type: ", 80 * wth + 10, zoneTail);
      doc.setTextColor("Green");
      doc.text(zone.cropType, 80 * wth + 40, zoneTail);

      // Soil Type
      zoneTail += 5;
      doc.setTextColor("Black");
      doc.text("Soil Type: ", 80 * wth + 10, zoneTail);
      doc.setTextColor("Green");
      doc.text(zone.soilType.toString(), 80 * wth + 40, zoneTail);

      // Wilting Point
      zoneTail += 5;
      doc.setTextColor("Black");
      doc.text("Wilting Point: ", 80 * wth + 10, zoneTail);
      doc.setTextColor("Green");
      doc.text(zone.wiltingPoint.toString(), 80 * wth + 40, zoneTail);

      // Saturation
      zoneTail += 5;
      doc.setTextColor("Black");
      doc.text("Saturation: ", 80 * wth + 10, zoneTail);
      doc.setTextColor("Green");
      doc.text(zone.saturation.toString(), 80 * wth + 40, zoneTail);

      // Field Capacity
      zoneTail += 5;
      doc.setTextColor("Black");
      doc.text("Field Capacity: ", 80 * wth + 10, zoneTail);
      doc.setTextColor("Green");
      doc.text(zone.fieldCapacity.toString(), 80 * wth + 40, zoneTail);
    }
  });

  // // Battery Status
  // {
  //   doc.setTextColor("Black")
  //   doc.text("Battery Level: ", 80 * wth + 10, 75)

  //   var batteryStatus = ""
  //   var batteryColor = "Green"
  //   if (input.battery > 3.6) batteryStatus = "High"
  //   else if (input.battery < 2.6) {
  //     batteryStatus = "Low"
  //     batteryColor = "Red"
  //   } else batteryStatus = "Medium"
  //   if (input.battery < 0) {
  //     batteryStatus = "No Sensors"
  //     batteryColor = "Green"
  //   }
  //   doc.setTextColor(batteryColor)
  //   doc.text(batteryStatus, 80 * wth + 40, 75)
  // }

  input.callback?.setCompletedProgress(40);

  let charts = input.lineChart;
  // console.log("chartsToReport", inputCharts)
  // let charts = Array.from(inputCharts.childNodes)
  // charts = charts.filter(x => x !== undefined)
  // let charts = lineCharts.filter((ele, index) => input.checkedIndex[index] == true)
  // console.log("chartsToReport", input.checkedIndex)

  const evp = input.evp;
  charts.unshift(evp);
  input.callback?.setCompletedProgress(60);

  tail = 130;
  Promise.all(charts.map((chart: any) => html2canvas(chart, { useCORS: true })))
    .then((canvasCharts) => {
      input.callback?.setCompletedProgress(80);
      canvasCharts.forEach((canvasChart, index) => {
        let tempWidth = 190;
        const chartWidth = canvasChart.width;
        const chartHeight = canvasChart.height;
        const wth2 = chartWidth / chartHeight;
        const imgChart = canvasChart.toDataURL("image/png", 0.5);
        if (index !== 0) {
          // tail + tempWidth / wth2 - 10 > 290
          doc.setTextColor("Black");
          doc.setFontSize(10);
          doc.text(pageNumber.toString(), 195, 285);
          doc.addPage();
          tail = 10;
          pageNumber++;
        }
        doc.addImage(imgChart, "png", 10, tail, tempWidth, tempWidth / wth2);
        tail += tempWidth / wth2 - 10;
      });
      doc.save(input.chosenField + "_" + stDate + "_" + edDate + ".pdf");
      input.callback?.setCompletedProgress(100);
    })
    .catch((err) => {
      console.error("Error: ", err);
      alert("Report Unavailable");
    });

  // html2canvas(chart, { useCORS: true })
  //   .then((canvasChart) => {
  //     let tempWidth = 190;
  //     const chartWidth = canvasChart.width;
  //     const chartHeight = canvasChart.height;
  //     const wth2 = chartWidth / chartHeight;
  //     const imgChart = canvasChart.toDataURL("image/png");
  //     doc.addImage(imgChart, "png", 5, 125, tempWidth, tempWidth / wth2);
  //     tail = 115 + tempWidth / wth2;
  //     if (tail > 290) {
  //       doc.setTextColor("Black");
  //       doc.setFontSize(10);
  //       doc.text(195, 285, pageNumber.toString());
  //       doc.addPage();
  //       tail = 10;
  //       pageNumber++;
  //     }
  //     const chart = input.evp;
  //     html2canvas(chart, { useCORS: true }).then((canvasBarChart) => {
  //       const chartWidth2 = canvasBarChart.width;
  //       const chartHeight2 = canvasBarChart.height;
  //       const wth22 = chartWidth2 / chartHeight2;
  //       const imgChart2 = canvasBarChart.toDataURL("image/png");
  //       doc.addImage(imgChart2, "png", 5, tail, 195, 190 / wth22);
  //       // Page Number
  //       doc.setTextColor("Black");
  //       doc.setFontSize(10);
  //       doc.text(195, 285, pageNumber.toString());
  //       // Info Window
  //       doc.setFontSize(12);
  //       doc.save(input.chosenField + "_" + stDate + "_" + edDate + ".pdf");
  //     });
  //   })
  //   .catch((err) => {
  //     console.error("Error: ", err);
  //     alert("Report Unavailable");
  //   });
}
