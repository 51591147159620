import React, { useEffect, useState } from "react";
import "./phone.scss";

import { ToastOptions, toast } from "react-toastify";
import { retriveUser, updateUser } from "../../actions/cognito";
import { EMAIL_REG, PASSWORD_REG } from "../../utilities";
import Confirm from "./Confirm";
import { Redirect, NavLink } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  Alert,
} from "reactstrap";
import PhoneInput from "react-phone-number-input";
import Widget from "../../components/Widget";
import Button from "../../components/button";

type FormValues = {
  username: string;
  expiredDate: Date | "";
  subType: string;
  phone: string;
  address: string;
  email: string;
  oldPassword: string;
  newPassword: string;
  newPwCfm: string;
  [name: string]: any;
};

const ToastOption: ToastOptions = {
  position: "top-right",
  autoClose: 3000,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: true,
};

const ProfilePage = () => {
  const [updateSuccess, setSuccess] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState<FormValues>({
    username: "",
    expiredDate: "",
    subType: "",
    phone: "",
    address: "",
    email: "",
    oldPassword: "",
    newPassword: "",
    newPwCfm: "",
  });
  const [confirmToggle, setConfirmToggle] = useState(false);

  const onClickChangeUser = (
    name: string
  ): React.MouseEventHandler<HTMLButtonElement> => {
    return (e) => {
      e.preventDefault();
      if (name === "email") {
      }

      setIsLoading(true);
      let param: Partial<FormValues> = { [name]: formState[name] };
      if (name === 'password') {
        param = { oldPassword: formState.oldPassword, newPassword: formState.newPassword }
      }
      updateUser(param)
        .then(() => {
          if (name === "email") {
            setConfirmToggle(true);
          } else {
            toast.success(
              <div>
                <span className="glyphicon glyphicon-info-sign ml-2 mr-2" />
                Update {name} was successful.
              </div>,
              { ...ToastOption }
            );
          }
        })
        .catch((err) => {
          toast.error(
            <div className="d-flex">
              <span className="glyphicon glyphicon-info-sign ml-2 mr-2" />
              <p>
                Update {name} failed because <br /> {err}.
              </p>
            </div>
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
  };

  useEffect(() => {
    setIsLoading(true);
    retriveUser()
      .then((res) => {
        let date: Date | "" = "";
        if (res.expiredDate) {
          let parts = res.expiredDate.split("-");
          date = new Date(
            parseInt(parts[2]),
            parseInt(parts[1]) - 1,
            parseInt(parts[0])
          );
        }
        setFormState((prev) => ({
          ...prev,
          username: res.userName,
          address: res.address || "",
          email: res.email,
          phone: res.phone || "",
          expiredDate: date,
          subType: res.subscriptionType || "",
        }));
      })
      .catch((error) => {
        console.log(error);
        // put a toast or else
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onChangeInput: (
    key: string,
    value?: string
  ) => React.ChangeEventHandler<HTMLInputElement> = (key, value) => {
    return (e) => {
      e.persist();
      setFormState((prev) => ({
        ...prev,
        [key]: value || e.target.value,
      }));
    };
  };

  const emailValid =
    formState.email.length > 0 ? EMAIL_REG.exec(formState.email) : true;
  const newPasswordValid =
    formState.newPassword.length > 0
      ? PASSWORD_REG.exec(formState.newPassword)
      : true;
  const oldPasswordNeeded =
    formState.newPassword.length > 0 && formState.oldPassword.length === 0;
  const oldPasswordNotNeeded =
    formState.newPassword.length === 0 && formState.oldPassword.length > 0;
  const passwordConfirmValid =
    formState.newPassword.length > 0
      ? formState.newPwCfm === formState.newPassword
      : true;
  const changePasswordEnabled =
    newPasswordValid &&
    !oldPasswordNeeded &&
    !oldPasswordNotNeeded &&
    passwordConfirmValid;

  if (updateSuccess) return <Redirect to={"/app/Dashboard"} />;
  return (
    <div className={"root"}>
      <Confirm
        isOpen={confirmToggle}
        toggleModal={() => setConfirmToggle((prev) => !prev)}
      />
      <Row>
        <Col sm={6}>
          <Widget title={<h1 className="mb-lg">Profile</h1>}>
            <h4>Please fill in the profile you want to modify.</h4>
            <br />
            <Form>
              <FormGroup>
                <Label for="username">Username</Label>
                <Input
                  bsSize="lg"
                  disabled
                  id="username"
                  value={formState.username}
                />
              </FormGroup>
              <FormGroup style={{ margin: "25px 0px 0px 0px" }}>
                <Label for="expiredDate">Expired Date</Label>
                <Input
                  bsSize="lg"
                  id="expiredDate"
                  disabled
                  value={
                    formState.expiredDate
                      ? `Valid until ${formState.expiredDate.getDate()}-${formState.expiredDate.getMonth() + 1
                      }-${formState.expiredDate.getFullYear()}`
                      : ""
                  }
                />
              </FormGroup>

              {formState.expiredDate && (
                <div className="text-end my-2">
                  {Math.floor(
                    (formState.expiredDate.getTime() - new Date().getTime()) /
                    1000 /
                    60 /
                    60 /
                    24
                  ) + " days left"}
                </div>
              )}

              <FormGroup>
                <Label for="subscriptionType">Subscrption Type</Label>
                <Input
                  bsSize="lg"
                  id="subscriptionType"
                  disabled
                  value={formState.subType}
                />
              </FormGroup>
              <NavLink
                to="/app/subscription"
                className={"text-decoration-none"}
              >
                <ButtonGroup
                  className="d-flex justify-content-between align-content-center"
                  style={{ margin: "15px 0px 0px 0px" }}
                >
                  <Button color="danger" disabled={isLoading}>
                    Renew Subscription
                  </Button>
                </ButtonGroup>
              </NavLink>
              <div style={{ margin: "25px 0px 0px 0px" }}>
                <FormGroup>
                  <Label for="input-phone">Phone</Label>
                  <PhoneInput
                    international
                    defaultCountry="AU"
                    value={formState.phone}
                    name="phone"
                    onChange={(e) => {
                      let phone = (e as string) || "";
                      setFormState((prev) => ({
                        ...prev,
                        phone,
                      }));
                    }}
                  />
                  {formState.phone === "" && (
                    <div style={{ margin: "15px 0px 0px 0px" }}>
                      <span className="text-info">
                        <span
                          className="glyphicon glyphicon-info-sign"
                          style={{ margin: "0px 8px 0px 0px" }}
                        />
                        If no phone number, you will not receive Moisture Alert
                        SMS from Aquaterra.
                      </span>
                    </div>
                  )}
                </FormGroup>
              </div>
              <ButtonGroup className="d-flex justify-content-between align-content-center">
                <Button
                  color="danger"
                  onClick={onClickChangeUser("phone")}
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Change Phone Number
                </Button>
              </ButtonGroup>

              <FormGroup style={{ margin: "25px 0px 0px 0px" }}>
                <Label for="input-address">Address</Label>
                <Input
                  bsSize="lg"
                  type="text"
                  value={formState.address}
                  name="address"
                  id="input-address"
                  onChange={onChangeInput("address")}
                />
              </FormGroup>
              <ButtonGroup
                className="d-flex justify-content-between align-content-center"
                style={{ margin: "15px 0px 0px 0px" }}
              >
                <Button
                  isLoading={isLoading}
                  disabled={isLoading}
                  color="danger"
                  onClick={onClickChangeUser("address")}
                >
                  Change Address
                </Button>
              </ButtonGroup>

              <FormGroup style={{ margin: "25px 0px 0px 0px" }}>
                <Label for="input-email">Email</Label>
                <span className="text-info" style={{ display: "block" }}>
                  <span
                    className="glyphicon glyphicon-info-sign"
                    style={{ margin: "0px 8px 0px 0px" }}
                  />
                  A verification code will be sent to your new email.
                </span>
                <Input
                  bsSize="lg"
                  type="email"
                  required
                  value={formState.email}
                  name="email"
                  id="input-email"
                  onChange={onChangeInput("email")}
                  style={{ margin: "10px 0px 0px 0px" }}
                />
                {!emailValid && (
                  <Alert
                    size="sm"
                    className="bg-danger font-weight-bold text-white border-0"
                  >
                    * Invalid Email Address
                  </Alert>
                )}
              </FormGroup>
              <ButtonGroup
                className="d-flex justify-content-between align-content-center"
                style={{ margin: "15px 0px 0px 0px" }}
              >
                <Button
                  isLoading={isLoading}
                  disabled={isLoading || !emailValid}
                  color="danger"
                  onClick={onClickChangeUser("email")}
                >
                  Change Email
                </Button>
              </ButtonGroup>

              <FormGroup style={{ margin: "25px 0px 0px 0px" }}>
                <Label for="input-old">Old Password</Label>
                <Input
                  bsSize="lg"
                  type="password"
                  value={formState.oldPassword}
                  name="oldPassword"
                  id="input-old"
                  onChange={onChangeInput("oldPassword")}
                />
                {oldPasswordNotNeeded && (
                  <Alert
                    size="sm"
                    className="bg-dark font-weight-bold text-white border-0"
                  >
                    * Not needed if you don't change your password.
                  </Alert>
                )}
                {oldPasswordNeeded && (
                  <Alert
                    size="sm"
                    className="bg-danger font-weight-bold text-white border-0"
                  >
                    * You need to verify your password if you want to change it.
                  </Alert>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="input-new">New Password</Label>
                <Input
                  bsSize="lg"
                  type="password"
                  value={formState.newPassword}
                  name="newPassword"
                  id="input-new"
                  onChange={onChangeInput("newPassword")}
                />
                {!newPasswordValid && (
                  <Alert
                    size="sm"
                    className="bg-danger font-weight-bold text-white border-0"
                  >
                    * At least 8 characters with lower and upper case letters
                    and numbers.
                  </Alert>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="input-newCfrm">New Password Confirm</Label>
                <Input
                  bsSize="lg"
                  type="password"
                  value={formState.newPasswordConfirm}
                  name="newPasswordCfrm"
                  id="input-new-confirm"
                  onChange={onChangeInput("newPwCfm")}
                />
                {!passwordConfirmValid && (
                  <Alert
                    size="sm"
                    className="bg-danger font-weight-bold text-white border-0"
                  >
                    * Inconsistent password
                  </Alert>
                )}
              </FormGroup>
              <ButtonGroup className="d-flex justify-content-between align-content-center">
                <Button
                  isLoading={isLoading}
                  disabled={!changePasswordEnabled || isLoading}
                  color="danger"
                  onClick={onClickChangeUser("password")}
                >
                  Change Password
                </Button>
              </ButtonGroup>

              <ButtonGroup
                className="d-flex justify-content-between align-content-center"
                style={{ margin: "50px 0px 0px 0px" }}
              >
                <Button
                  color="default"
                  onClick={() => setSuccess(true)}
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Back to Home
                </Button>
              </ButtonGroup>
            </Form>
          </Widget>
        </Col>
      </Row>
    </div>
  );
};

export default ProfilePage;
